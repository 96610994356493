import Global from './global/global.js';

class UserManagementLogin extends Global {

    constructor() {
        super();

        this.formSubmitBind();
    }

    formSubmitBind = () => {
        const form = document.querySelector('form');

        form.addEventListener('submit', async e => {
            e.preventDefault();

            const formData = new FormData(form);
            const response = await fetch(`${form.action}${jsData.redirect}`, {
                method: "POST",
                body: formData,
            });

            const notification = await response.json();
            this.formatFormResponse(notification);
        });
    }
}

new UserManagementLogin();