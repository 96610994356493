// Loaded using SCSS via webpack
// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/solid'
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'

import Menu from './menu.js';
import Notification from './notification.js';

require('regenerator-runtime');
require('jquery');
require('popper.js');
require('bootstrap');

export default class Global {

    constructor() {
        this.setLocale();
        this.setSiteUrl();
        this.setAuth();
        this.notification = new Notification(this.locale);
        this.menu = new Menu();
    }

    setLocale = () => {
        if (langObject) {
            this.locale = langObject
        }
    }

    setSiteUrl = () => {
        if (jsData.siteUrl) {
            this.siteUrl = jsData.siteUrl
        }
    }

    setAuth = () => {
        if (jsData.auth) {
            this.auth = jsData.auth
        }
    }

    formatFormResponse = response => {
        if (response.url !== null) {
            setTimeout(function(){
                window.location.href = response.url;
            }, 750);
        }

        if (response.errors.length > 0) {
            response.errors.forEach(error => {
                this.notification.show(this.notification.types.danger, error);
            });
        }

        if (response.success.length > 0) {
            this.notification.show(this.notification.types.success, response.success);
        }
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}