import Cookies from 'js-cookie';

export default class Menu {
    navigationCookieName = 'isNavToggled';
    isNavToggled = false;
    navigationContainer = null;
    toggleButton = null;
    pageContainer = null;
    budgetTableContainer = null;
    topBar = null;
    navigationLinks = null;

    constructor() {
        this.menuInit();
    }

    menuInit = () => {
        try {
            this.navigationContainer = document.querySelector('.navigation');
            this.toggleButton = this.navigationContainer && this.navigationContainer.querySelector('.navigationbar__footer .toggle');
            this.pageContainer = document.querySelector('.page-container');
            this.budgetTableContainer = document.querySelector('.table-container');
            this.topBar = document.querySelector('.topbar');
            this.navigationLinks = document.querySelectorAll('.collapsed');

            if (this.toggleButton) {
                this.menuToggleInit();
                this.bindToggleButton();
                this.bindItemButtons();
            }
        } catch (error) {
            this.notification.showDefaultError(error);
        }
    }

    menuToggleInit = () => {
        const { navigationCookieName } = this;

        this.isNavToggled = Cookies.get(navigationCookieName) === "true" ? true : false;
    }

    bindToggleButton() {
        const { navigationCookieName } = this;

        this.toggleButton.addEventListener('click', () => {
            Cookies.remove(navigationCookieName, { path: '/' });
            this.isNavToggled = !this.isNavToggled;
            Cookies.set(navigationCookieName, this.isNavToggled, { path: '/' });
            this.navigationContainer.classList.toggle('toggled');
            this.pageContainer.classList.toggle('page-container--navigation-narrow');
            this.topBar.classList.toggle('navigation-toggled');
        });

        document.addEventListener('click', (event) => {
            this.navigationLinks.forEach(link => {
                const item = link.parentElement;
                const innerContainer = item.querySelector('.collapse');

                if (
                    event.target.parentElement !== link
                    && event.target !== link
                ) {
                    item.classList.remove('collapsed');
                    innerContainer.classList.remove('show');
                }
            });
        });
    }

    bindItemButtons() {
        this.navigationLinks.forEach(link => {
            const item = link.parentElement;
            const innerContainer = item.querySelector('.collapse');

            link.addEventListener('click', () => {
                this.turnOffCollapseDivs(link);
                item.classList.toggle('collapsed');
                innerContainer.classList.toggle('show');
            });
        });
    }

    turnOffCollapseDivs(clickedItem) {
        this.navigationLinks.forEach(link => {
            if (link !== clickedItem) {
                const item = link.parentElement;
                const innerContainer = item.querySelector('.collapse');

                item.classList.remove('collapsed');
                innerContainer.classList.remove('show');
            }
        });
    }

}